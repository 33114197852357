<template>
  <div>
    <div class="static-container">
      <div class="static-title">{{ $trans('问题', '问题') }}</div>
      <div class="static-paragraph" v-for="item in question" :key="item.id">
        <div class="static-paragraph-title">
          {{ $trans(item.title, item.title) }}
        </div>
        <div class="static-p" v-for="(i, index) in item.text" :key="index">
          {{ $trans(i, i) }}
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/layout/Footer.vue'
import { question } from '@/util/resource/static.jsx'
export default {
  components: {
    Footer,
  },
  computed: {
    question() {
      return question()
    },
  },
}
</script>
<style scoped>
.static-container {
  width: 800px;
  padding: 100px 0;
  margin: 0 auto;
}
.static-title {
  font-family: 'myCoolFontMedium';
  font-size: 20px;
  margin-bottom: 60px;
}
.static-paragraph {
  margin-bottom: 70px;
}
.static-paragraph-title {
  font-family: 'myCoolFontMedium';
  font-size: 14px;
  line-height: 34px;
}
.static-p {
  font-size: 14px;
  line-height: 34px;
}
</style>
